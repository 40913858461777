import { COLORS, gapLg } from 'assets/styled';
import styled, { css } from 'styled-components';

export const MODEL_CONFIGURATION_CONTAINER_HEIGHT = 110;

export enum ErrorPositioningEnum {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

interface StyledProps {
  isToggled: boolean;
  withoutLine: boolean;
  isErrorVisible: boolean;
  errorPositioning: ErrorPositioningEnum;
}

export const RowTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${gapLg};
`;

export const RowBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: ${MODEL_CONFIGURATION_CONTAINER_HEIGHT}rem;
  min-width: 1200rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.GRAY_100};
    `}
`;

export const ModelNameLabel = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  color: ${COLORS.GREEN};
`;

export const FixedAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
`;
export const DeviationVariableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
`;
export const RowTopRightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50rem;
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5rem;
  z-index: 2;
`;

export const ModelNameTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ToggleSwitchLabel = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  color: ${COLORS.GRAY_100};
  ${(props: StyledProps) =>
    props.isToggled &&
    css`
      color: ${COLORS.GREEN};
    `};
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  margin-right: 5rem;
  color: ${COLORS.BLACK};
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  top: -25rem; // Adjust accordingly
  background-color: rgba(255, 0, 0, 0.8); // Adjust as needed
  color: white;
  padding: 5rem;
  border-radius: 5rem;
  font-size: 14rem; // Adjust accordingly
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  ${(props: StyledProps) =>
    props.isErrorVisible &&
    css`
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    `};
  ${(props: StyledProps) => {
    switch (props.errorPositioning) {
      case ErrorPositioningEnum.LEFT:
        return css`
          left: 0rem;
        `;
      case ErrorPositioningEnum.CENTER:
        return css`
          left: 310rem;
        `;
      case ErrorPositioningEnum.RIGHT:
        return css`
          left: 610rem;
        `;
    }
  }}
`;

export const ManageQuestionVisibilityLabel = styled.label`
  font-size: 16rem;
  cursor: pointer;
  color: ${COLORS.GREEN};
  &:hover {
    text-decoration: underline;
  }
`;
