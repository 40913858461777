import { borderRadiusSm, COLORS, gapSm, paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelectedLanguage: boolean;
}

export const LanguageImage = styled.img`
  margin: 0rem;
  width: 25rem;
  height: 25rem;
  cursor: pointer;
`;

export const LanguagesDropdown = styled.div`
  position: absolute;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusSm};
  justify-content: center;
  padding-left: 15rem;
  padding-right: 15rem;
  right: 0;
  top: 60rem;
  z-index: 1;
  border: 1rem solid ${COLORS.BORDER};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  gap: ${gapSm};
`;

export const LanguageLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelectedLanguage &&
    css`
      color: ${COLORS.GREEN};
    `};
  padding-top: ${paddingXs};
  padding-bottom: ${paddingXs};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GRAY_100};
  display: flex;
  width: 100%;
`;
