import { CSSProperties, Dispatch, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import { BackDrop, StyledModal } from './Modal.styled';
import { Level } from './type';

interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  onBackDropClick?: () => void;
  level?: Level;
  modalStyle?: CSSProperties;
  pwId?: string;
}

export const Modal = ({
  children,
  isOpen,
  setIsOpen,
  onBackDropClick,
  level = 'FIRST',
  modalStyle,
  pwId,
}: IModalProps) => {
  if (!isOpen) return <></>;

  return ReactDOM.createPortal(
    <>
      <BackDrop
        level={level}
        isOpen={isOpen}
        onClick={() => {
          setIsOpen && setIsOpen(false);
          onBackDropClick && onBackDropClick();
        }}
      />
      <StyledModal style={modalStyle} level={level} data-testid={pwId}>
        {children}
      </StyledModal>
    </>,
    document.body
  );
};
