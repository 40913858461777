import { CSSProperties } from 'react';
import {
  CheckboxInput,
  CheckboxSpan,
  ClickableContainer,
  Container,
} from './Checkbox.styled';

interface ICheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (param: boolean) => void;
  style?: CSSProperties;
}

export const Checkbox = ({
  label,
  onChange,
  isChecked,
  style,
}: ICheckboxProps) => {
  return (
    <Container>
      <ClickableContainer onClick={() => onChange(!isChecked)}>
        <CheckboxInput
          onChange={() => {
            onChange(!isChecked);
          }}
          checked={isChecked}
        />
        <CheckboxSpan style={style}>{label}</CheckboxSpan>
      </ClickableContainer>
    </Container>
  );
};
