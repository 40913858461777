import { marginLg } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  background: #f6fcf7;
  padding: ${marginLg};
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  padding-bottom: ${marginLg};
  font-weight: 300;
`;
