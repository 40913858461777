import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  createConfiguration,
  updateConfiguration,
} from 'services/ModelConfigurationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  ICreateConfigurationData,
  IUpdateConfigurationData,
} from 'types/Configuration.types';

export const useCreateConfiguration = () =>
  useMutation(
    (createConfigurationData: ICreateConfigurationData) => {
      return createConfiguration(createConfigurationData);
    },
    {
      onSuccess: (data) => {
        toast.success('Configuration applied');
        queryClient.invalidateQueries(ReactQueryKeys.GET_MODEL_CONFIGURATIONS);
      },
      onError: (error) => {
        toast.error('Error while applying configuration');
        queryClient.invalidateQueries(ReactQueryKeys.GET_MODEL_CONFIGURATIONS);
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_CONFIGURATION,
    }
  );

export const useUpdateConfiguration = (configurationId: number) =>
  useMutation(
    (updateConfigurationData: IUpdateConfigurationData) => {
      return updateConfiguration(configurationId, updateConfigurationData);
    },
    {
      onSuccess: (data) => {
        toast.success('Configuration applied');
        queryClient.invalidateQueries(ReactQueryKeys.GET_MODEL_CONFIGURATIONS);
        // on Success
      },
      onError: (error) => {
        toast.error('Error while applying configuration');
        queryClient.invalidateQueries(ReactQueryKeys.GET_MODEL_CONFIGURATIONS);
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.UPDATE_CONFIGURATION,
    }
  );
