import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';

interface StyledProps {
  isFormikPlaceholder: boolean;
  isSelectPlaceholder: boolean;
  isTranslatedToTop: boolean;
  isTextAreaPlaceholder: boolean;
}

export const PlaceholderSpan = styled.span`
  pointer-events: none;
  position: absolute;
  left: 13rem;

  ${(props: StyledProps) =>
    props.isFormikPlaceholder
      ? props.isSelectPlaceholder
        ? css`
            top: 50%;
          `
        : props.isTextAreaPlaceholder
        ? css`
            top: 30rem;
          `
        : css`
            top: 38%;
          `
      : props.isTextAreaPlaceholder
      ? css`
          top: 30rem;
        `
      : css`
          top: 50%;
        `}

  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 3rem;
  color: ${COLORS.BLACK};
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease,
    background-color 0.3s ease;

  ${(props: StyledProps) =>
    props.isTranslatedToTop &&
    css`
      top: -7rem;
      font-size: 12rem;
    `}
`;
