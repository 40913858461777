import {
  Container,
  LeftSide,
  RightSide,
  RowSpaceBetween,
  SwitchersWrapper,
} from './Header.styled';
import { ReactComponent as LogyxLogoSVG } from 'assets/images/logyxLogo.svg';
import { SUPPLIERS } from 'navigation/routes';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import useNavigateWithQuery from 'navigation/useNavigateWithQuery';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled/colors';

const Header = () => {
  const navigate = useNavigateWithQuery();

  return (
    <Container>
      <RowSpaceBetween>
        <LeftSide>
          <Icon
            svg={LogyxLogoSVG}
            onClick={() => navigate(SUPPLIERS)}
            color={COLORS.BLACK}
            size={123}
            noRipple
          />
        </LeftSide>
        <RightSide>
          <SwitchersWrapper>
            <ToggleDarkMode />
            <LanguageSwitcher />
          </SwitchersWrapper>
          {/* <NavigationLabelsWrapper>
            <NavigationLabel
              onClick={() => {
                // Temporarily removed; #739 Dealer panel model list
                // navigate(MODELS);
              }}
              isSelected={location.pathname.includes(MODELS)}
            >
              {t('Models')}
            </NavigationLabel>

            <NavigationLabel
              onClick={() => {
                navigate(SETTINGS);
              }}
              isSelected={location.pathname.includes(SETTINGS)}
            >
              {t('Settings')}
            </NavigationLabel>
          </NavigationLabelsWrapper> */}
        </RightSide>
      </RowSpaceBetween>
    </Container>
  );
};

export default Header;
