export const validateDeviationInput = (
  value: number,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setErrorVisible: React.Dispatch<React.SetStateAction<boolean>>,
  type: string
) => {
  if (!isNaN(value) && value >= -100 && value <= 1000) {
    setError(null);
    return true;
  } else {
    setErrorVisible(true);
    setError(
      `Invalid ${type} value. It must be a number between -100 and 1000`
    );
    return false;
  }
};

export const validateFixedPriceInput = (
  value: number,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setErrorVisible: React.Dispatch<React.SetStateAction<boolean>>,
  type: string
) => {
  if (!isNaN(value) && value >= -10000 && value <= 10000) {
    setError(null);
    return true;
  } else {
    setErrorVisible(true);
    setError(
      `Invalid ${type} value. It must be a number between -10000 and 10000`
    );
    return false;
  }
};

export const getWidthBasedOfTextLength = (text: string) => {
  if (text.length > 120) {
    return '800rem';
  } else if (text.length > 40) {
    return '400rem';
  } else return '200rem';
};
