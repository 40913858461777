import i18n from 'providers/i18n/i18n';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ILanguageQueryParamListenerProps {
  children: React.ReactNode;
}

const LanguageQueryParamListener = ({
  children,
}: ILanguageQueryParamListenerProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang: string = queryParams.get('lang') || '';

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);

  return <>{children}</>;
};

export default LanguageQueryParamListener;
