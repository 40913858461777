import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getDealerPanelModelQuestions } from 'services/ModelService';
import {
  getModelDocumentVisibility,
  submitVisibilityConfiguration,
} from 'services/VisibilityConfigurationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IDocVisibilityOptionEnum } from './constants';

export interface IVisibilityConfigurationDTO {
  values: object;
  modelId: number;
}
export const useSubmitVisibilityConfiguration = (onSuccess: () => void) => {
  const { t } = useTranslation();
  return useMutation(
    (visiblityConfigurationDTO: IVisibilityConfigurationDTO) => {
      return submitVisibilityConfiguration(visiblityConfigurationDTO);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Applied document visibility configuration'));
        onSuccess && onSuccess();
      },
      onError: (error) => {
        toast.error(
          t('Error while applying document visibility configuration')
        );
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SUBMIT_VISIBILITY_CONFIGURATION,
    }
  );
};

export const useGetDealerPanelModelQuestions = () => {
  const { t } = useTranslation();
  return useMutation(
    (modelId: number) => {
      return getDealerPanelModelQuestions(modelId);
    },
    {
      onSuccess: (data) => {
        //
      },
      onError: (error) => {
        toast.error(t('Failed to obtain model questions'));
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.GET_DEALER_PANEL_MODEL_QUESTIONS,
    }
  );
};

export const useGetDocumentVisibilityOptions = () => {
  return [
    { label: 'Quotation', value: IDocVisibilityOptionEnum.QUOTATION },
    { label: 'Sales order', value: IDocVisibilityOptionEnum.SALES_ORDER },
    { label: 'Purchase order', value: IDocVisibilityOptionEnum.PURCHASE_ORDER },
    { label: 'Sales invoice', value: IDocVisibilityOptionEnum.SALES_INVOICE },
  ];
};

export const useGetModelDocumentVisibility = () => {
  const { t } = useTranslation();
  return useMutation(
    (modelId: number) => {
      return getModelDocumentVisibility(modelId);
    },
    {
      onSuccess: (data) => {
        //
      },
      onError: (error) => {
        toast.error(t('Failed to obtain document visibility options'));
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.GET_MODEL_DOCUMENT_VISIBILITY,
    }
  );
};
