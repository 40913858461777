import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isChecked: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 50rem;
  height: 10rem;
  border-radius: 5rem;
  cursor: pointer;

  align-self: center;
  ${(props: StyledProps) =>
    props.isChecked &&
    css`
      background-color: ${COLORS.GREEN_DISABLED};
    `};
  ${(props: StyledProps) =>
    !props.isChecked &&
    css`
      background-color: ${COLORS.GRAY_100};
    `};
`;

export const Circle = styled.div`
  width: 25rem;
  height: 25rem;
  border-radius: 13rem;
  position: absolute;
  top: -8rem;
  ${(props: StyledProps) =>
    props.isChecked
      ? css`
          left: 50%;
          transition: 200ms ease-in-out;
          background-color: ${COLORS.GREEN};
        `
      : css`
          transition: 200ms ease-in-out;
          background-color: ${COLORS.GREEN_DISABLED};
        `};
`;
