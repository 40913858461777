export const COLORS = {
  WHITE: 'var(--color-white)',
  BLACK: 'var(--color-black)',
  BACKDROP: 'var(--color-backdrop)',
  GREEN: 'var(--color-green)',
  GREEN_DISABLED: 'var(--color-green-disabled)',
  RED: 'var(--color-red)',
  RED_100: 'var(--color-red-100)',
  GRAY_100: 'var(--color-grey-100)',
  GRAY_200: 'var(--color-grey-200)',
  GRAY_300: 'var(--color-grey-300)',
  LIGHT_GREEN: 'var(--color-light-green)',
  TEAL: 'var(--color-teal)',
  SALMON: 'var(--color-salmon)',
  PURPLE: 'var(--color-purple)',
  YELLOW: 'var(--color-yellow)',
  BLUE: 'var(--color-blue)',
  STRICT_WHITE: 'var(--color-strict-white)',
  STRICT_BLACK: 'var(--color-strict-black)',
  BACKGROUND: 'var(--color-background)',
  BORDER: 'var(--color-border)',
  FORM_ELEMENT_HOVER: 'var(--color-form-element-hover)',
  RIPPLE_EFFECT_OPACITY: 'var(--color-ripple-effect-opacity)',
  RIPPLE_CHILD_EFFECT_OPACITY: 'var(--color-ripple-child-effect-opacity)',
};
