import ReactSelect from 'react-select';
import { Option } from './type';
import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';

interface StyledProps {
  isError: boolean;
  height: string;
}

export const StyledReactSelect = styled(ReactSelect<Option, boolean>)`
  .select__option {
    padding: 10rem 5rem;
    color: ${COLORS.BLACK};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${COLORS.GRAY_300};
    }
    padding-left: 10rem;
  }

  .select__single-value {
    color: ${COLORS.BLACK};
  }

  .select__input-container {
    color: ${COLORS.BLACK};
  }

  .select__option--is-selected {
    background-color: ${COLORS.GRAY_200};
  }

  .select__option--is-focused {
    background-color: ${COLORS.LIGHT_GREEN};
  }

  .select__indicator {
    height: 41rem;
  }

  .select__menu {
    border-radius: ${borderRadiusSm};
  }

  .select__menu-list {
    background-color: ${COLORS.WHITE};
    border-radius: ${borderRadiusSm};
    border: 1px solid ${COLORS.GRAY_200};
    color: ${COLORS.GRAY_100};
    max-height: 300rem;
  }

  .select__control {
    ${(props: StyledProps) =>
      props.height &&
      css`
        min-height: ${props.height};
      `};
    ${(props: StyledProps) =>
      props.isError
        ? css`
            border: 1rem solid ${COLORS.RED_100};
          `
        : css`
            border: 1rem solid ${COLORS.GRAY_200};
          `}
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: ${borderRadiusSm};
    background-color: ${COLORS.WHITE};
    color: ${COLORS.GRAY_200};
    &:hover {
      background-color: ${COLORS.WHITE};
      border: 1rem solid ${COLORS.BLACK};
      box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
    }
  }

  .select__control--is-focused {
    border: 1rem solid ${COLORS.GREEN} !important;
    box-shadow: 0 0 0 0.5rem ${COLORS.GREEN} !important;
  }

  .select__control--is-disabled {
    cursor: not-allowed;
    pointer-events: all;
    background-color: ${COLORS.GRAY_200};

    &:hover {
      background-color: ${COLORS.GRAY_200};
      border-color: ${COLORS.GRAY_300};
      box-shadow: none;
    }
  }

  .controlFocused {
    @extend .control;
    box-shadow: 3rem 3rem 5rem ${COLORS.GRAY_300};
  }
`;

export const Indicator = styled(`img`)`
  padding: 10rem 8rem;
`;

export const ErrorMessageWrapper = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  bottom: -12rem;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const RelativeDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
