import axios from 'axios';
import { config } from 'config';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { IRootReducerState, store } from 'store/store';

const API_URL = config.api.baseUrl;

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {},
});

apiClient.interceptors.request.use(
  async (config) => {
    const state: IRootReducerState = store.getState();
    const intentUUID = state.commonInfo.intentUUID;
    config.headers = {
      Authorization: `Bearer ${intentUUID}`,
      'X-Locale': i18n.resolvedLanguage || FALLBACK_LOCALE,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default apiClient;
