import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';

interface StyledProps {
  width: string;
  withoutWordBreak: boolean;
  isBelow: boolean;
  isHuge: boolean;
}

export const ChildWrapper = styled.div`
  cursor: help;
`;

export const Scrollable = styled.div`
  max-height: 200rem;
  ${(props: StyledProps) =>
    props.isHuge &&
    css`
      max-height: 500rem; // temporarily enlarged
      width: 1000rem;
    `};
  overflow-y: scroll;
  padding-top: 4rem;
  padding-bottom: 3rem;
  padding-left: 6rem;
  padding-right: 6rem;

  ::-webkit-scrollbar {
    width: 5rem;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GREEN_DISABLED};
    border-radius: 5rem;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    height: 1rem;
  }
  width: ${(props: StyledProps) => props.width || '300rem'};
`;

export const TextWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%) scale(0);
  transform-origin: bottom center;
  z-index: 1;
  visibility: visible;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  border: 1px solid ${COLORS.GREEN};
  padding: 3rem 4rem;
  color: ${COLORS.BLACK};
  cursor: default;
  ${(props: StyledProps) =>
    props.isBelow &&
    css`
      top: 100%;
      height: fit-content;
      transform-origin: top center;
    `};
  width: ${(props: StyledProps) => props.width || ''};
`;

export const Tip = styled.div`
  position: absolute;
  left: 10%;
  bottom: -10rem;
  transform: translate(-50%, -5rem) rotateZ(45deg);
  content: '';
  display: block;
  width: 10rem;
  height: 10rem;
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.GREEN};
  border-right: 1px solid ${COLORS.GREEN};
  ${(props: StyledProps) =>
    props.isBelow &&
    css`
      top: 0rem;
      bottom: 0;
      border-bottom: none;
      border-right: none;
      border-top: 1px solid ${COLORS.GREEN};
      border-left: 1px solid ${COLORS.GREEN};
    `};
`;

export const Container = styled.div`
  position: relative;

  &:hover ${TextWrapper} {
    visibility: visible;
    transform: translateX(-10%) scale(1);
  }
`;

export const Text = styled.label`
  font-size: 16rem;
  line-height: 27rem;
  font-weight: 400;
  word-break: break-word;
  ${(props: StyledProps) =>
    props.withoutWordBreak &&
    css`
      word-break: normal;
      word-wrap: break-word;
      white-space: pre-line;
    `};
`;
