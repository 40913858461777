import { PlaceholderSpan } from './Placeholder.styled';

interface IPlaceholderProps {
  isTranslatedToTop: boolean;
  isFormikPlaceholder: boolean;
  isTextAreaPlaceholder?: boolean;
  isSelectPlaceholder: boolean;
  placeholder: string | undefined;
}

const Placeholder = ({
  isTranslatedToTop,
  isFormikPlaceholder,
  isSelectPlaceholder,
  isTextAreaPlaceholder,
  placeholder,
}: IPlaceholderProps) => {
  return (
    <PlaceholderSpan
      isTranslatedToTop={isTranslatedToTop}
      isFormikPlaceholder={isFormikPlaceholder}
      isSelectPlaceholder={isSelectPlaceholder}
      isTextAreaPlaceholder={isTextAreaPlaceholder}
    >
      {placeholder}
    </PlaceholderSpan>
  );
};

export default Placeholder;
