import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { decode } from 'js-base64';
import { setMetadata } from 'store/Common/actions/common';

interface IMetadataQueryParamListenerProps {
  children: React.ReactNode;
}

const MetadataQueryParamListener = ({
  children,
}: IMetadataQueryParamListenerProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const base64Metadata = queryParams.get('metadata');

  let metadata: any;
  if (base64Metadata) {
    metadata = decode(base64Metadata);
  }

  useEffect(() => {
    if (metadata) {
      dispatch(setMetadata(JSON.parse(metadata)));
    }
  }, [metadata]);

  return <>{children}</>;
};

export default MetadataQueryParamListener;
