import DashboardLayout from 'components/Layout/DashboardLayout/DashboardLayout';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import LoadingScreenOverlayProvider from 'providers/LoadingScreenOverlayProvider/LoadingScreenOverlayProvider';
import { Route, Routes } from 'react-router-dom';
import { MODELS, SETTINGS, SUPPLIERS } from './routes';
import SuppliersPage from 'pages/SuppliersPage/SuppliersPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import ModelsPage from 'pages/ModelsPage/ModelsPage';

export default function AppNavigationRouter() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<DashboardLayout />}>
        <Route path={'/'} element={<LoadingScreenOverlayProvider />}>
          <Route path={SETTINGS} element={<SettingsPage />} />
          <Route path={MODELS} element={<ModelsPage />} />
          <Route path={SUPPLIERS} element={<SuppliersPage />} />
        </Route>
      </Route>
    </Routes>
  );
}
