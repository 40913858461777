import styled, { css } from 'styled-components';

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 1000rem;
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface MarginTopHeaderHeightProps {
  isHeaderExpanded: boolean;
}
export const MarginTopHeaderHeight = styled.div`
  margin-top: 75rem;
`;
