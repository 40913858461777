import {
  COLORS,
  borderRadiusSm,
  marginLg,
  marginMd,
  paddingLg,
  paddingMd,
  paddingSm,
} from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${COLORS.BACKGROUND};
  padding: ${marginLg};
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  padding-bottom: ${marginLg};
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100rem;
`;

export const BackChevron = styled.img`
  margin-right: 5rem;
  cursor: pointer;
`;

export const BackLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24.5rem;
  margin-left: 5rem;
  cursor: pointer;
  color: ${COLORS.BLACK};
`;

export const RowPaddingBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

export const CategoriesContainer = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;
  border: 1rem solid ${COLORS.GRAY_100};
  padding: ${paddingMd};
  padding-top: 0;
  padding-bottom: 0;
`;

export const SupplierCompanyName = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  padding-left: ${paddingSm};
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const NoContentLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  padding-top: ${paddingLg};
  color: ${COLORS.BLACK};
`;

export const InputWrapper = styled.div`
  height: fit-content;
`;
