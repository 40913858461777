import { CSSProperties } from 'react';
import { ClearIndicatorProps } from 'react-select';
import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import { XCircle } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

export const CancelIcon = styled.img`
  cursor: pointer;
  width: 20rem;
  height: 20rem;
`;

const CustomClearIcon = () => {
  return <Icon svg={XCircle} color={COLORS.RED} />;
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const {
    children = <CustomClearIcon />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      {children}
    </div>
  );
};

export default ClearIndicator;
