import DarkModeQueryParamListener from 'listeners/DarkModeQueryParamListener';
import IntentUUIDListener from 'listeners/IntentUUIDListener';
import LanguageQueryParamListener from 'listeners/LanguageQueryParamListener';
import MetadataQueryParamListener from 'listeners/MetadataQueryParamListener';
import WebhookURLQueryParamListener from 'listeners/WebhookURLQueryParamListener';
import AppNavigationRouter from 'navigation/AppNavigationRouter';
import { AppThemeProvider } from 'providers/AppThemeProvider/AppThemeProvider';
import { HtmlThemeProvider } from 'providers/HtmlThemeProvider/HtmlThemeProvider';

function App() {
  return (
    <DarkModeQueryParamListener>
      <WebhookURLQueryParamListener>
        <MetadataQueryParamListener>
          <LanguageQueryParamListener>
            <IntentUUIDListener>
              <AppThemeProvider>
                <HtmlThemeProvider>
                  <AppNavigationRouter />
                </HtmlThemeProvider>
              </AppThemeProvider>
            </IntentUUIDListener>
          </LanguageQueryParamListener>
        </MetadataQueryParamListener>
      </WebhookURLQueryParamListener>
    </DarkModeQueryParamListener>
  );
}

export default App;
