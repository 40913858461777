import { useEffect, useRef, useState } from 'react';
import { useIsFetching } from 'react-query';
import { useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IRootReducerState } from 'store/store';

export const useShowLoadingScreen = () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const hasFetchedInitialSuppliers = useHasFetchedInitalSuppliers();
  const intentUUID = useSelector(
    (state: IRootReducerState) => state.commonInfo.intentUUID
  );

  useEffect(() => {
    setTimeout(() => setShowLoadingScreen(false), 1000);
  }, [showLoadingScreen]);
  return showLoadingScreen || !hasFetchedInitialSuppliers || !intentUUID;
};

const useHasFetchedInitalSuppliers = () => {
  const isFetchingSuppliers = useIsFetching(ReactQueryKeys.GET_SUPPLIERS);
  const hasFetchedInitialSuppliersRef = useRef<boolean>(false);

  useEffect(() => {
    if (isFetchingSuppliers === 0) {
      hasFetchedInitialSuppliersRef.current = true;
    }
  }, [isFetchingSuppliers]);

  return hasFetchedInitialSuppliersRef.current;
};
