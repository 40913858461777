import { Container, Title } from './SettingsPage.styled';

const SettingsPage = () => {
  return (
    <Container>
      <Title>Settings</Title>
    </Container>
  );
};

export default SettingsPage;
