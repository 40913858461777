import Tooltip from 'components/Tooltip/Tooltip';
import {
  CheckboxWrapper,
  Container,
  DeviationVariableWrapper,
  FixedAmountWrapper,
  Label,
  ModelNameLabel,
  ModelNameTooltipWrapper,
  RowTop,
  RowBottom,
  ToggleSwitchLabel,
  TooltipWrapper,
  RowTopRightPart,
  ToggleSwitchWrapper,
  ErrorOverlay,
  ErrorPositioningEnum,
  ManageQuestionVisibilityLabel,
} from './ModelConfiguration.styled';

import Button from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import {
  PriceInput,
  SymbolPlacementEnum,
  SymbolTypeEnum,
} from 'components/Input/PriceInput/PriceInput';
import { IModel } from 'types/Model.types';
import { useCreateConfiguration, useUpdateConfiguration } from './hooks';
import {
  ICreateConfigurationData,
  IUpdateConfigurationData,
} from 'types/Configuration.types';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { config } from 'config';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Base64 } from 'js-base64';
import {
  getWidthBasedOfTextLength,
  validateDeviationInput,
  validateFixedPriceInput,
} from './helpers';
import Icon from 'components/Icon/Icon';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IModelConfigurationProps {
  model: IModel;
  index: number;
  withoutLine: boolean;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
}

export const MODEL_CONFIGURATION_INITIAL_VALUES = {
  baseDeviation: 0,
  surchargeDeviation: 0,
  fixedPrice: 0,
  excludeSurcharge: false,
  roundPrices: false,
  isActive: true,
};

const LOGYX_FORMS_APP_URL = config.logyx.logyxFormsUrl;

const ModelConfiguration = ({
  model,
  index,
  withoutLine,
  setManageVisibilityModelId,
}: IModelConfigurationProps) => {
  const configuration = model.configurations?.[0];
  const { t } = useTranslation();
  const [baseDeviation, setBaseDeviation] = useState<number>(
    configuration
      ? Number(configuration.base_deviation)
      : MODEL_CONFIGURATION_INITIAL_VALUES.baseDeviation
  );
  const [surchargeDeviation, setSurchargeDeviation] = useState<number>(
    configuration
      ? Number(configuration.surcharge_deviation)
      : MODEL_CONFIGURATION_INITIAL_VALUES.surchargeDeviation
  );
  const [fixedPrice, setFixedPrice] = useState<number>(
    configuration
      ? Number(configuration.fixed_amount)
      : MODEL_CONFIGURATION_INITIAL_VALUES.fixedPrice
  );
  const [excludeSurcharge, setExcludeSurcharge] = useState<boolean>(
    configuration
      ? configuration.exclude_surcharge
      : MODEL_CONFIGURATION_INITIAL_VALUES.excludeSurcharge
  );
  const [roundPrices, setRoundPrices] = useState<boolean>(
    configuration
      ? configuration.round_prices
      : MODEL_CONFIGURATION_INITIAL_VALUES.roundPrices
  );
  const [isActive, setIsActive] = useState<boolean>(
    configuration
      ? configuration.is_active
      : MODEL_CONFIGURATION_INITIAL_VALUES.isActive
  );

  const [hasConfigurationChanged, setHasConfigurationChanged] = useState(false);
  const [baseDeviationError, setBaseDeviationError] = useState<string | null>(
    null
  );
  const [isBaseErrorVisible, setIsBaseErrorVisible] = useState(false);
  const [fixedPriceError, setFixedPriceError] = useState<string | null>(null);
  const [isFixedPriceErrorVisible, setIsFixedPriceErrorVisible] =
    useState(false);
  const [surchargeDeviationError, setSurchargeDeviationError] = useState<
    string | null
  >(null);
  const [isSurchargeErrorVisible, setIsSurchargeErrorVisible] = useState(false);

  const {
    mutate: createConfiguration,
    isLoading: createIsLoading,
    isSuccess: createIsSuccess,
  } = useCreateConfiguration();
  const {
    mutate: updateConfiguration,
    isLoading: updateIsLoading,
    isSuccess: updateIsSuccess,
  } = useUpdateConfiguration(configuration?.id);

  const intentUUID = useSelector(
    (state: IRootReducerState) => state.commonInfo.intentUUID
  );
  const handleTest = () => {
    if (intentUUID) {
      const queryParamsObj = {
        modelId: model.id,
        intentUUID: Base64.encodeURL(intentUUID),
        isTest: true,
      };
      const fullUrl = getUrlWithQueryParams(
        `${LOGYX_FORMS_APP_URL}/configure/`,
        queryParamsObj
      );

      window.open(fullUrl, 'LOGYX_FORMS');
    }
  };
  const handleApply = () => {
    if (!configuration) {
      const createConfigurationData: ICreateConfigurationData = {
        model_id: model.id,
        base_deviation: baseDeviation,
        surcharge_deviation: surchargeDeviation,
        fixed_amount: fixedPrice,
        exclude_surcharge: excludeSurcharge,
        round_prices: roundPrices,
        is_active: isActive,
      };
      createConfiguration(createConfigurationData);
    } else {
      const updateConfigurationData: IUpdateConfigurationData = {
        base_deviation: baseDeviation,
        surcharge_deviation: surchargeDeviation,
        fixed_amount: fixedPrice,
        exclude_surcharge: excludeSurcharge,
        round_prices: roundPrices,
        is_active: isActive,
      };
      updateConfiguration(updateConfigurationData);
    }
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess) {
      setHasConfigurationChanged(false);
    }
  }, [createIsSuccess, updateIsSuccess]);

  useEffect(() => {
    if (isBaseErrorVisible) {
      const timeoutId = setTimeout(() => setIsBaseErrorVisible(false), 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isBaseErrorVisible]);

  useEffect(() => {
    if (isFixedPriceErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsFixedPriceErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isFixedPriceErrorVisible]);

  useEffect(() => {
    if (isSurchargeErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsSurchargeErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isSurchargeErrorVisible]);

  const hasEmptyValues = () => {
    const isEmpty = (value: any) => {
      return [undefined, null, ''].includes(value);
    };
    return (
      isEmpty(baseDeviation) ||
      isEmpty(surchargeDeviation) ||
      isEmpty(fixedPrice)
    );
  };

  const hasErrors = () => {
    return (
      !!baseDeviationError || !!fixedPriceError || !!surchargeDeviationError
    );
  };

  const pricelist = model.general.pricelist;
  const pricelistVersion = model.general.pricelist_version;

  const pricelistTooltipText = pricelist
    ? `, ${t('Pricelist')}: ${pricelist},`
    : '';

  const pricelistVersionTooltipText = pricelistVersion
    ? `${t('Pricelist Version')}: ${pricelistVersion}`
    : '';

  const text = `${t('ID')}: ${model.id},
              ${
                model.version_number
                  ? ` ${t('Version ID')}: ${model.version_number}`
                  : ' '
              }${pricelistTooltipText} ${pricelistVersionTooltipText}`;

  return (
    <Container withoutLine={withoutLine}>
      <RowTop>
        <ModelNameTooltipWrapper>
          <ModelNameLabel>{model.general.name}</ModelNameLabel>
          <TooltipWrapper>
            <Tooltip
              width={getWidthBasedOfTextLength(text)}
              text={text}
              isBelow={index === 0}
            >
              <Icon svg={Info} color={COLORS.GREEN} />
            </Tooltip>
          </TooltipWrapper>
        </ModelNameTooltipWrapper>
        <RowTopRightPart>
          <ManageQuestionVisibilityLabel
            onClick={() => setManageVisibilityModelId(model.id)}
          >
            {t('Manage document visibility')}
          </ManageQuestionVisibilityLabel>
          <Button
            label={t('Test (inc. 21% VAT)')}
            secondary
            height="25rem"
            onClick={handleTest}
          />
          <Button
            disabled={
              !hasConfigurationChanged ||
              createIsLoading ||
              updateIsLoading ||
              hasEmptyValues() ||
              hasErrors()
            }
            label={t('Apply')}
            primary
            height="25rem"
            onClick={handleApply}
          />
          <ToggleSwitchWrapper>
            <ToggleSwitchLabel isToggled={isActive}>
              {isActive ? t('On') : t('Off')}
            </ToggleSwitchLabel>
            <ToggleSwitch
              isChecked={isActive}
              onClick={() => {
                setHasConfigurationChanged(true);
                setIsActive(!isActive);
              }}
            />
          </ToggleSwitchWrapper>
        </RowTopRightPart>
      </RowTop>
      <RowBottom>
        <DeviationVariableWrapper>
          <Label>{t('Deviation base price exc. VAT:')}</Label>
          <PriceInput
            width="80rem"
            value={baseDeviation}
            setValue={(value: number) => {
              setHasConfigurationChanged(true);
              if (
                validateDeviationInput(
                  value,
                  setBaseDeviationError,
                  setIsBaseErrorVisible,
                  'base deviation'
                )
              ) {
                setBaseDeviation(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={!!baseDeviationError && isBaseErrorVisible}
            validate={(value: number) =>
              validateDeviationInput(
                value,
                setBaseDeviationError,
                setIsBaseErrorVisible,
                'base deviation'
              )
            }
          />
        </DeviationVariableWrapper>
        <FixedAmountWrapper>
          <Label>{t('Fixed amount exc. VAT:')}</Label>
          <PriceInput
            value={fixedPrice}
            setValue={(value: number) => {
              setHasConfigurationChanged(true);
              if (
                validateFixedPriceInput(
                  value,
                  setFixedPriceError,
                  setIsFixedPriceErrorVisible,
                  'fixed price'
                )
              ) {
                setFixedPrice(value);
              }
            }}
            symbol={SymbolTypeEnum.EURO}
            symbolPlacement={SymbolPlacementEnum.BEFORE}
            hasError={!!fixedPriceError && isFixedPriceErrorVisible}
            validate={(value: number) =>
              validateFixedPriceInput(
                value,
                setFixedPriceError,
                setIsFixedPriceErrorVisible,
                'fixed price'
              )
            }
          />
        </FixedAmountWrapper>
        <CheckboxWrapper>
          <Checkbox
            isChecked={excludeSurcharge}
            label={t('Exclude surcharge amount')}
            onChange={() => {
              setHasConfigurationChanged(true);
              setExcludeSurcharge(!excludeSurcharge);
            }}
          />
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            isChecked={roundPrices}
            label={t('Round prices')}
            onChange={() => {
              setHasConfigurationChanged(true);
              setRoundPrices(!roundPrices);
            }}
          />
        </CheckboxWrapper>
        <DeviationVariableWrapper>
          <Label>{t('Deviation surcharge amount exc. VAT:')}</Label>
          <PriceInput
            value={surchargeDeviation}
            setValue={(value: number) => {
              setHasConfigurationChanged(true);
              if (
                validateDeviationInput(
                  value,
                  setSurchargeDeviationError,
                  setIsSurchargeErrorVisible,
                  'surcharge deviation'
                )
              ) {
                setSurchargeDeviation(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={!!surchargeDeviationError && isSurchargeErrorVisible}
            validate={(value: number) =>
              validateDeviationInput(
                value,
                setSurchargeDeviationError,
                setIsSurchargeErrorVisible,
                'surcharge deviation'
              )
            }
          />
        </DeviationVariableWrapper>
        <ErrorOverlay
          errorPositioning={ErrorPositioningEnum.LEFT}
          isErrorVisible={baseDeviationError && isBaseErrorVisible}
        >
          {baseDeviationError}
        </ErrorOverlay>
        <ErrorOverlay
          errorPositioning={ErrorPositioningEnum.CENTER}
          isErrorVisible={fixedPriceError && isFixedPriceErrorVisible}
        >
          {fixedPriceError}
        </ErrorOverlay>
        <ErrorOverlay
          errorPositioning={ErrorPositioningEnum.RIGHT}
          isErrorVisible={surchargeDeviationError && isSurchargeErrorVisible}
        >
          {surchargeDeviationError}
        </ErrorOverlay>
      </RowBottom>
    </Container>
  );
};

export default ModelConfiguration;
