import { ISupplier } from 'types/Supplier.types';
import {
  AbsoluteCircleWrapper,
  Container,
  Image,
  SupplierIdLabel,
  NameLabel,
  RowCenter,
} from './SupplierCard.styled';
import DefaultImage from 'assets/images/default-image.png';
import { MODELS } from 'navigation/routes';
import useNavigateWithQuery from 'navigation/useNavigateWithQuery';
import { CaretRight } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';

interface ISupplierCardProps {
  supplier: ISupplier;
}

const SupplierCard = ({ supplier }: ISupplierCardProps) => {
  const navigate = useNavigateWithQuery();

  const handleOnClick = () => {
    navigate(MODELS, {
      state: {
        supplierId: supplier.id,
        companyName: supplier.name,
      },
    });
  };

  return (
    <Container data-testid={`supplier-card-${supplier.id}`}>
      <AbsoluteCircleWrapper>
        <SupplierIdLabel>{supplier.id}</SupplierIdLabel>
      </AbsoluteCircleWrapper>

      <Image src={DefaultImage} onClick={handleOnClick} />
      <RowCenter>
        <NameLabel onClick={handleOnClick}>
          {supplier.name ? supplier.name : 'Unnamed supplier company'}
        </NameLabel>
        <Icon svg={CaretRight} color={COLORS.GREEN} onClick={handleOnClick} />
      </RowCenter>
    </Container>
  );
};
export default SupplierCard;
