import { getModelConfigurations } from 'services/ModelConfigurationService';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useQuery } from 'react-query';
import i18n from 'providers/i18n/i18n';

export const useGetModelConfigurations = (
  supplierId: string | null,
  searchBy: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
      supplierId,
      searchBy,
      i18n.resolvedLanguage,
    ],
    queryFn: () => {
      return getModelConfigurations(supplierId, searchBy);
    },
    enabled: !!supplierId, // Temporarily removed; #739 Dealer panel model list
  });

export const useGetSupplierIdAndCompanyNameFromNavigationState = () => {
  const { state } = useLocation();
  if (state) {
    return [state.supplierId, state.companyName];
  } else {
    return [null, null];
  }
};
