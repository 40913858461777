import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import {
  LayoutColumn,
  LayoutRow,
  MarginTopHeaderHeight,
} from './DashboardLayout.styled';

export function DashboardLayout() {
  return (
    <LayoutRow>
      <LayoutColumn>
        <Header />
        <MarginTopHeaderHeight />
        <Outlet />
      </LayoutColumn>
    </LayoutRow>
  );
}

export default DashboardLayout;
