import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_INTENT_UUID,
  SET_METADATA,
  SET_WEBHOOK_URL,
  SET_IS_DARK_MODE,
} from 'store/constants';

export interface ICommonReducerState {
  intentUUID: string;
  webhookURL: string;
  metadata: any;
  darkMode: boolean;
}

const INIT_STATE: ICommonReducerState = {
  intentUUID: '',
  webhookURL: '',
  metadata: null,
  darkMode: false,
};

const commonReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_INTENT_UUID:
      return { ...state, intentUUID: action.payload };
    case SET_WEBHOOK_URL:
      return { ...state, webhookURL: action.payload };
    case SET_METADATA:
      return { ...state, metadata: action.payload };
    case SET_IS_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
