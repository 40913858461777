import styled from 'styled-components';
import { COLORS, gapMd, marginMd, marginXs } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';

interface StyledProps {
  height: string;
  overflowVisible: boolean;
}

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${gapMd};
  height: ${(props: StyledProps) => props.height};
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-bottom: ${marginXs};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20rem;
  padding-top: 10rem;
`;

export const FormTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75rem;
`;

export const FormTitle = styled.label`
  width: 100%;
  font-size: 18rem;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const NoContentLabel = styled.label`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  min-height: 200rem;
  color: ${COLORS.BLACK};
`;

export const QuestionWrapper = styled.div`
  height: 50rem;
`;

export const Scrollable = styled.div`
  height: calc(100% - 75rem - 60rem);
  padding: ${marginMd};
  overflow: ${(props: StyledProps) =>
    props.overflowVisible ? 'visible' : 'scroll'};
`;

export const ErrorOcurredLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18rem;
  font-weight: 500;
  height: 100%;
`;
