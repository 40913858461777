export const useTooltipPosition = () => {
  const positionTooltip = (
    tooltip: HTMLDivElement,
    parent: HTMLDivElement,
    tip: HTMLDivElement
  ) => {
    const toolTipRect = tooltip.getBoundingClientRect();
    if (toolTipRect.x < 0) {
      tooltip.style.left = `${
        parent.getBoundingClientRect().width / 2 + -toolTipRect.x
      }rem`;
      tip.style.left = `${
        parent.parentElement?.getBoundingClientRect().width
      }rem`;
    }
  };

  return positionTooltip;
};
