import ENLangImg from 'assets/images/locales/en.png';
import NLLangImg from 'assets/images/locales/nl.png';
import FRLangImg from 'assets/images/locales/fr.png';
import DALangImg from 'assets/images/locales/da.png';
import DELangImg from 'assets/images/locales/de.png';
import ESLangImg from 'assets/images/locales/es.png';
import NOLangImg from 'assets/images/locales/no.png';
import SRLangImg from 'assets/images/locales/sr.png';
import SVLangImg from 'assets/images/locales/sv.png';

export const getSelectedLanguageIcon = (lng: string) => {
  switch (lng) {
    case 'en':
      return ENLangImg;
    case 'nl':
      return NLLangImg;
    case 'fr':
      return FRLangImg;
    case 'da':
      return DALangImg;
    case 'de':
      return DELangImg;
    case 'es':
      return ESLangImg;
    case 'no':
      return NOLangImg;
    case 'sr':
      return SRLangImg;
    case 'sv':
      return SVLangImg;
    default:
      return ENLangImg;
  }
};
