import { COLORS } from 'assets/styled/colors';
import Icon from 'components/Icon/Icon';
import { CSSProperties, InputHTMLAttributes, useRef } from 'react';
import {
  Container,
  StyledInput,
  StyledTextareaInput,
  IconWrap,
} from './Input.styled';
import { SymbolPlacementEnum } from './PriceInput/PriceInput';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties;
  error?: boolean;
  errorMessage?: string;
  wrapperStyles?: CSSProperties;
  isTextArea?: boolean;
  icon?: any;
  iconPlacement?: SymbolPlacementEnum;
  placeholder: string;
}
const Input = ({ ...props }: IInput) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  return (
    <Container
      wrapperStyles={props?.wrapperStyles}
      onClick={() => {
        inputRef?.current?.focus();
      }}
    >
      {props.isTextArea ? (
        <StyledTextareaInput
          data-testid={props['pwId']}
          ref={inputRef}
          {...props}
          id={props.name}
          style={props.style}
          onChange={(e: any) => {
            props.onChange && props.onChange(e);
          }}
          onBlur={(e: any) => {
            props.onBlur && props.onBlur(e);
          }}
          type={props.type || 'text'}
          spellcheck="false"
          placeholder={props.placeholder}
          isError={!!props.errorMessage}
        />
      ) : (
        <>
          <StyledInput
            data-testid={props['pwId']}
            ref={inputRef}
            {...props}
            id={props.name}
            style={props.style}
            onChange={(e: any) => {
              props.onChange && props.onChange(e);
            }}
            onBlur={(e: any) => {
              props.onBlur && props.onBlur(e);
            }}
            type={props.type || 'text'}
            placeholder={props.placeholder}
            isError={!!props.errorMessage}
            iconPlacement={props.iconPlacement}
          />
          {props.icon && (
            <IconWrap iconPlacement={props.iconPlacement}>
              <Icon
                svg={props.icon}
                size={20}
                color={COLORS.BLACK}
                pointer={false}
                weight="regular"
              />
            </IconWrap>
          )}
        </>
      )}
      {/* {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>} */}
    </Container>
  );
};

export default Input;
