import { Circle, Container } from './ToggleSwitch.styled';

interface IToggleSwitchProps {
  isChecked: boolean;
  onClick: () => void;
}

const ToggleSwitch = ({ isChecked, onClick }: IToggleSwitchProps) => {
  return (
    <Container onClick={onClick} isChecked={isChecked}>
      <Circle onClick={onClick} isChecked={isChecked} />
    </Container>
  );
};

export default ToggleSwitch;
