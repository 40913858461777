import { COLORS, marginLg } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${COLORS.BACKGROUND};
  padding: ${marginLg};
  height: 100%;
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const SuppliersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const TitleInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30rem;
`;

export const NoContentLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  color: ${COLORS.BLACK};
`;
