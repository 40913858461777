import {
  CategoryNameLabel,
  Container,
  ModelConfigurationsWrapper,
  CategoryNameAndChevronWrapper,
} from './Category.styled';
import ModelConfiguration from './ModelConfiguration/ModelConfiguration';
import { Dispatch, SetStateAction, useState } from 'react';
import { ICategory } from 'types/Category.types';
import { IModel } from 'types/Model.types';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled/colors';
import { CaretUp, CaretDown } from '@phosphor-icons/react';

interface ICategoryProps {
  category: ICategory;
  withoutLine?: boolean;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
}

const Category = ({
  category,
  withoutLine = false,
  setManageVisibilityModelId,
}: ICategoryProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // Sort models by id in ascending order
  const sortedModels = [...category.models].sort(
    (a: IModel, b: IModel) => a.id - b.id
  );

  const handleOnClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container withoutLine={withoutLine}>
      <CategoryNameAndChevronWrapper onClick={handleOnClick}>
        <CategoryNameLabel>{category.name}</CategoryNameLabel>
        <Icon
          svg={isExpanded ? CaretUp : CaretDown}
          color={COLORS.BLACK}
          onClick={handleOnClick}
        />
      </CategoryNameAndChevronWrapper>

      <ModelConfigurationsWrapper
        isExpanded={isExpanded}
        numberOfModelsInCategory={category.models.length} // Used for transition speed
      >
        {sortedModels.map((model: IModel, index: number) => (
          <ModelConfiguration
            model={model}
            key={index}
            index={index}
            withoutLine={category.models.length - 1 === index}
            setManageVisibilityModelId={setManageVisibilityModelId}
          />
        ))}
      </ModelConfigurationsWrapper>
    </Container>
  );
};

export default Category;
