import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { decode } from 'js-base64';
import { setIntentUUID } from 'store/Common/actions/common';

interface IIntentUUIDListenerProps {
  children: React.ReactNode;
}

const IntentUUIDListener = ({ children }: IIntentUUIDListenerProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const base64IntentUUID = queryParams.get('intent');
  let intentUUID: any;
  if (base64IntentUUID) {
    intentUUID = decode(base64IntentUUID);
  }

  useEffect(() => {
    if (intentUUID) {
      dispatch(setIntentUUID(intentUUID));
    }
  }, [intentUUID]);

  return <>{children}</>;
};

export default IntentUUIDListener;
