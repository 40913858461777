import { LogoBigImg, Container } from './LoadingScreenOverlay.styled';
import LogyxLogoSVG from 'assets/images/logyxLogo.svg';

const LoadingScreenOverlay = () => {
  return (
    <Container>
      <LogoBigImg src={LogyxLogoSVG} alt="" />
    </Container>
  );
};

export default LoadingScreenOverlay;
