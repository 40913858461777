import {
  SET_INTENT_UUID,
  SET_WEBHOOK_URL,
  SET_METADATA,
  SET_IS_DARK_MODE,
} from 'store/constants';

export const setIntentUUID = (intentUUID: string) => ({
  type: SET_INTENT_UUID,
  payload: intentUUID,
});

export const setWebhookURL = (intentUUID: string) => ({
  type: SET_WEBHOOK_URL,
  payload: intentUUID,
});

export const setMetadata = (metadata: object) => ({
  type: SET_METADATA,
  payload: metadata,
});

export const setDarkMode = (darkMode: boolean) => ({
  type: SET_IS_DARK_MODE,
  payload: darkMode,
});
