import { Option } from 'components/Select/type';
import { IQuestion } from 'types/Question.types';

export const parseQuestionsObjectResponseIntoArray = (questionsObj: object) => {
  return Object.keys(questionsObj).map((questionKey: string) => {
    return {
      ...questionsObj[questionKey],
      name: questionsObj[questionKey].name
        ? questionsObj[questionKey].name
        : questionKey,
      initialKey: questionsObj[questionKey].initialKey
        ? questionsObj[questionKey].initialKey
        : questionKey,
    };
  });
};

export const getInitialValuesFromQuestions = (
  questions: IQuestion[],
  documentVisibilityData: any,
  documentVisibilityOptions: Option[]
) => {
  const fetchedVisibilityData =
    documentVisibilityData?.data?.document_visibility_options;

  const initialValues = {};
  const allSelectedDefaultValue = documentVisibilityOptions.map(
    (option) => option.value
  );
  questions.forEach((question: IQuestion) => {
    if (fetchedVisibilityData?.[question.initialKey]) {
      initialValues[question.initialKey] =
        fetchedVisibilityData[question.initialKey];
    } else {
      initialValues[question.initialKey] = allSelectedDefaultValue;
    }
  });

  return initialValues;
};
