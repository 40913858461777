import {
  BackLabel,
  Container,
  BackWrapper,
  Title,
  RowPaddingBottom,
  CategoriesContainer,
  SupplierCompanyName,
  NoContentLabel,
  NoContentLabelWrapper,
  InputWrapper,
} from './ModelsPage.styled';
import { SUPPLIERS } from 'navigation/routes';
import Input from 'components/Input/Input';
import debounce from 'lodash.debounce';
import { ChangeEvent, useState } from 'react';
import Loader from 'components/Loader/Loader';
import {
  useGetModelConfigurations,
  useGetSupplierIdAndCompanyNameFromNavigationState,
} from './hooks';
import Category from './Category/Category';
import { useTranslation } from 'react-i18next';
import useNavigateWithQuery from 'navigation/useNavigateWithQuery';
import { formatModelsIntoCategories } from './helpers';
import { ICategory } from 'types/Category.types';
import ManageModelQuestionDocumentVisibilityModal from './ManageModelQuestionDocumentVisibilityModal/ManageModelQuestionDocumentVisibilityModal';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { MagnifyingGlass, CaretLeft } from '@phosphor-icons/react';
import Skeleton from '@mui/material/Skeleton';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const ModelsPage = () => {
  const navigate = useNavigateWithQuery();
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = debounce(
    (searchBy: string) => setSearchBy(searchBy),
    500
  );
  const [supplierId, supplierCompanyName] =
    useGetSupplierIdAndCompanyNameFromNavigationState();

  const { data, isLoading } = useGetModelConfigurations(supplierId, searchBy);
  const categories: ICategory[] = formatModelsIntoCategories(data);

  const [manageVisibilityModelId, setManageVisibilityModelId] = useState<
    number | null
  >(null);

  const handleOnClick = () => {
    navigate(SUPPLIERS);
  };

  const { isDesktop, isBigTablet } = useBreakpointFlag();

  const getSkeletonNumber = () => {
    if (isBigTablet) {
      return 8;
    } else if (isDesktop) {
      return 12;
    } else {
      return 9;
    }
  };

  const renderLoader = () => {
    return Array.from({ length: getSkeletonNumber() }, (_, index) => (
      <div style={{ paddingTop: 10 }} key={`skeleton-row-${index}`}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={'55rem'}
          style={{ backgroundColor: 'transparent' }}
        />
      </div>
    ));
  };

  return (
    <Container>
      <BackWrapper onClick={handleOnClick}>
        <Icon svg={CaretLeft} color={COLORS.BLACK} onClick={handleOnClick} />
        <BackLabel>{t('Back')}</BackLabel>
      </BackWrapper>
      <RowPaddingBottom>
        <div>
          <Title>{t('Models')}</Title>
          <SupplierCompanyName>
            {supplierCompanyName ? `(${supplierCompanyName})` : ''}
          </SupplierCompanyName>
        </div>
        <InputWrapper>
          <Input
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'300rem'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              debouncedSearchBy(e.target.value);
            }}
          />
        </InputWrapper>
      </RowPaddingBottom>
      {isLoading && renderLoader()}
      {categories.length ? (
        <CategoriesContainer>
          {categories.map((category: ICategory, index: number) => (
            <Category
              key={index}
              category={category}
              withoutLine={categories.length - 1 === index}
              setManageVisibilityModelId={setManageVisibilityModelId}
            />
          ))}
        </CategoriesContainer>
      ) : (
        <NoContentLabelWrapper>
          {!isLoading && (
            <NoContentLabel>
              {t('No models found for this supplier')}
            </NoContentLabel>
          )}
        </NoContentLabelWrapper>
      )}
      <ManageModelQuestionDocumentVisibilityModal
        manageVisibilityModelId={manageVisibilityModelId}
        setManageVisibilityModelId={setManageVisibilityModelId}
      />
    </Container>
  );
};

export default ModelsPage;
