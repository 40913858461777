import { CSSProperties } from 'react';

import styled from 'styled-components';
import { css } from 'styled-components';
import { COLORS } from '../../assets/styled/colors';
import {
  paddingXs,
  gapSm,
  borderRadiusSm,
  paddingSm,
  paddingMd,
} from 'assets/styled';
import { SymbolPlacementEnum } from './PriceInput/PriceInput';

interface StyledProps {
  search: boolean;
  height: string;
  width: string;
  wrapperStyles?: CSSProperties;
  isError: boolean;
  iconPlacement: SymbolPlacementEnum;
}

export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  position: relative;
  ${({ wrapperStyles }: StyledProps) => wrapperStyles}
`;

export const StyledInput = styled.input`
  height: ${(props: StyledProps) => props.height || '40rem'};
  border: none;
  border: 1rem solid ${COLORS.GRAY_100};
  background-color: ${COLORS.WHITE};
  border-radius: 20rem;
  flex-grow: 0;
  width: ${(props: StyledProps) => props.width};

  outline: none !important;
  transition: all 0.3s;

  :focus {
    border: 1rem solid ${COLORS.GREEN};
  }

  :hover {
    border: 1rem solid ${COLORS.GREEN};
    background-color: ${COLORS.GRAY_300};
  }

  ${(props: StyledProps) =>
    props.isError
      ? css`
          border: 1rem solid ${COLORS.RED_100};
        `
      : css`
          border: 1rem solid ${COLORS.GRAY_100};
        `}

  ::placeholder {
    font-size: 14rem;
    line-height: 21rem;
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
  padding-left: ${paddingSm};
  padding-right: ${paddingMd};
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.iconPlacement === SymbolPlacementEnum.BEFORE &&
    css`
      padding-left: ${paddingMd};
      padding-right: ${paddingSm};
    `}
`;

export const StyledTextareaInput = styled.textarea`
  height: ${(props: StyledProps) => props.height || '150rem'};
  font-family: inherit;
  border: 1rem solid ${COLORS.GRAY_100};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  outline: none !important;

  :focus {
    box-shadow: 0rem 2rem 2rem 0rem ${COLORS.WHITE};
    border: 1rem solid ${COLORS.GREEN};
  }

  :hover {
    border: 1rem solid ${COLORS.GREEN};
    background-color: ${COLORS.GRAY_300};
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
`;

export const ErrorMessage = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 10rem;
`;

export const IconWrap = styled.div`
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 25%;

  ${(props: StyledProps) =>
    props.iconPlacement === SymbolPlacementEnum.BEFORE
      ? css`
          left: 10rem;
        `
      : css`
          right: 10rem;
        `}
`;
