import { ChangeEvent, useState } from 'react';
import {
  Container,
  NoContentLabel,
  TitleInputWrapper,
  SuppliersContainer,
  Title,
} from './SuppliersPage.styled';
import { useGetSuppliers } from './hooks';
import { SUPPLIERS_PER_PAGE } from './constants';
import Loader from 'components/Loader/Loader';
import { ISupplier } from 'types/Supplier.types';
import SupplierCard from './SupplierCard/SupplierCard';
import Input from 'components/Input/Input';
import debounce from 'lodash.debounce';
import Pagination from 'components/Pagination/Pagination';
import useNavigateWithQuery from 'navigation/useNavigateWithQuery';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from 'pages/ModelsPage/ModelsPage.styled';
import { MagnifyingGlass } from '@phosphor-icons/react';

const SuppliersPage = () => {
  const navigate = useNavigateWithQuery();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const {
    data: suppliersData,
    isLoading,
    isSuccess,
  } = useGetSuppliers(page, SUPPLIERS_PER_PAGE, searchBy);

  const debouncedSearchBy = debounce(
    (searchBy: string) => setSearchBy(searchBy),
    500
  );

  // Temporarily removed; #739 Dealer panel model list
  // if (searchBy === '' && suppliersData?.total === 0 && isSuccess) {
  //   navigate(MODELS, {
  //     state: { supplierId: null },
  //   });
  // }

  return (
    <Container>
      <TitleInputWrapper>
        <Title>{t('Suppliers')}</Title>
        <InputWrapper>
          <Input
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'300rem'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              debouncedSearchBy(e.target.value);
            }}
          />
        </InputWrapper>
      </TitleInputWrapper>
      {isLoading && <Loader />}
      {suppliersData?.suppliers?.length ? (
        <>
          <SuppliersContainer>
            {suppliersData?.suppliers
              ?.sort((a: any, b: any) => a.id - b.id)
              .map((supplier: ISupplier, index: number) => (
                <SupplierCard supplier={supplier} key={index} />
              ))}
          </SuppliersContainer>
          <Pagination
            page={page}
            setPage={setPage}
            total={suppliersData?.total}
            perPage={SUPPLIERS_PER_PAGE}
          />
        </>
      ) : (
        <NoContentLabel>{t('No suppliers found')}</NoContentLabel>
      )}
    </Container>
  );
};

export default SuppliersPage;
