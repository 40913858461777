import { CurrentItemsLabel, RowCenter } from './Pagination.styled';
import { COLORS, marginXs } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface IPaginatedProps {
  page: number;
  perPage: number;
  total: number;
  setPage: (page: number) => void;
}

export const Pagination = ({
  page,
  perPage,
  total,
  setPage,
}: IPaginatedProps) => {
  const getSecondPaginationNumber = () => {
    if (page * perPage > total) {
      return total;
    } else {
      return page * perPage;
    }
  };
  const hasNextPage = perPage * page < total;

  const handleLoadPage = (newPage: number) => {
    if (newPage === 0) {
      return;
    }
    setPage(newPage);
  };

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <RowCenter>
      <Icon
        svg={CaretLeft}
        color={
          page === 1
            ? darkMode
              ? COLORS.GRAY_300
              : COLORS.GRAY_100
            : COLORS.GREEN
        }
        onClick={() => handleLoadPage(page - 1)}
        marginRight={marginXs}
      />
      <CurrentItemsLabel>
        {(page - 1) * perPage + 1} - {getSecondPaginationNumber()}
      </CurrentItemsLabel>
      &nbsp; of {total}
      <Icon
        svg={CaretRight}
        color={
          hasNextPage
            ? COLORS.GREEN
            : darkMode
            ? COLORS.GRAY_300
            : COLORS.GRAY_100
        }
        onClick={() => hasNextPage && handleLoadPage(page + 1)}
        marginLeft={marginXs}
      />
    </RowCenter>
  );
};

export default Pagination;
