import LoadingScreenOverlay from 'components/LoadingScreenOverlay/LoadingScreenOverlay';
import { Outlet } from 'react-router';
import { useShowLoadingScreen } from './hooks';

const LoadingScreenOverlayProvider = () => {
  const showLoadingScreen = useShowLoadingScreen();
  return (
    <>
      {showLoadingScreen ? <LoadingScreenOverlay /> : null}
      <Outlet />
    </>
  );
};

export default LoadingScreenOverlayProvider;
