import styled, { css } from 'styled-components';
import { borderRadiusSm, COLORS, transition } from 'assets/styled';

interface StyledProps {
  primary: boolean;
  secondary: boolean;
  table: boolean;
  red: boolean;
  redOutline: boolean;
  link: boolean;
  width: string;
  height: string;
  color: string;
  block: boolean;
}
const border = `1rem solid ${COLORS.GREEN}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:has(svg) {
    padding: 6rem;
    border: ${border};
    border-radius: ${borderRadiusSm};
  }
`;

export const FileLabel = styled.label`
  padding: 12rem 16rem 12rem 14rem;
  font-weight: 700;
  line-height: 16rem;
  font-size: 12rem;
  border-radius: 4rem;
  border: none;
  cursor: pointer;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 12rem 16rem 12rem 14rem;
  font-weight: 700;
  line-height: 16rem;
  font-size: 12rem;
  border-radius: ${borderRadiusSm};
  border: none;
  height: 40rem;
  width: ${(props: StyledProps) => props.width};
  transition: ${transition};

  &:disabled {
    cursor: not-allowed !important;
  }
  color: ${(props: StyledProps) => props.color || '#1f2937'};

  ${(props: StyledProps) =>
    props.primary &&
    css`
      background-color: ${COLORS.GREEN};
      color: ${COLORS.WHITE};
      gap: 10rem;
      height: 40rem;
      width: ${props.width};
      font-weight: 300;
      font-size: 14rem;
      &:hover:enabled {
        box-shadow: 0 0 0 1rem ${COLORS.WHITE};
      }
      &:disabled {
        background-color: ${COLORS.GREEN_DISABLED};
      }
    `}

  ${(props: StyledProps) =>
    props.secondary &&
    css`
      background-color: transparent;
      color: ${COLORS.GREEN};
      border: ${border};
      font-weight: 300;
      width: ${props.width};
      font-size: 14rem;

      &:hover:enabled {
        color: ${COLORS.WHITE};
        background-color: ${COLORS.GREEN};
        box-shadow: 0 0 0 1rem ${COLORS.GREEN};
      }

      &:disabled {
        opacity: 0.5;
      }
    `}


  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `}


	${(props: StyledProps) =>
    props.red &&
    css`
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    `}

    ${(props: StyledProps) =>
    props.redOutline &&
    css`
      font-size: 14rem;
      line-height: 21rem;
      font-weight: 300;
      background-color: #edf4fa;
      border: 1rem solid ${COLORS.RED};
      color: ${COLORS.BLACK};
      &:hover {
        background-color: ${COLORS.RED};
        color: ${COLORS.WHITE};
      }
    `}
`;
