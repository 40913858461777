import { useSelector, useDispatch } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Container } from './ToggleDarkMode.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { setDarkMode } from 'store/Common/actions/common';
import { Sun, Moon } from '@phosphor-icons/react';

const ToggleDarkMode = () => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const handleDarkModeChange = () => {
    dispatch(setDarkMode(!darkMode));
  };

  return (
    <Container onClick={handleDarkModeChange}>
      <Icon
        svg={darkMode ? Sun : Moon}
        color={COLORS.GREEN}
        size={25}
        onClick={handleDarkModeChange}
      />
    </Container>
  );
};

export default ToggleDarkMode;
