import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_IS_DARK_MODE } from 'store/constants';

interface IDarkModeQueryParamListenerProps {
  children: React.ReactNode;
}

const DarkModeQueryParamListener = ({
  children,
}: IDarkModeQueryParamListenerProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDarkMode: boolean = queryParams.get('isDarkMode') === 'true';

  useEffect(() => {
    dispatch({ type: SET_IS_DARK_MODE, payload: isDarkMode });
  }, [isDarkMode]);

  return <>{children}</>;
};

export default DarkModeQueryParamListener;
