import { ICategory } from 'types/Category.types';
import { IModel } from 'types/Model.types';

export const formatModelsIntoCategories = (
  modelsArray: IModel[]
): ICategory[] => {
  if (!modelsArray) {
    return [];
  }

  // Define the shape of the accumulator
  const initialAcc: { [key: string]: IModel[] } = {};

  // Using reduce to accumulate models into categories
  const categoryObj = modelsArray.reduce((acc, model) => {
    // Determine the category for the current model, defaulting to 'Other'
    const categoryName = model.general.category || 'Other';

    // If this category hasn't been seen before, initialize it with an empty array
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }

    // Add the current model to its determined category
    acc[categoryName].push(model);
    return acc; // Return the accumulator for the next iteration
  }, initialAcc); // Pass the initial accumulator with the correct type

  // Convert the accumulated object into an array of category objects
  const categories: ICategory[] = Object.entries(categoryObj).map(
    ([categoryName, models]) => {
      return { name: categoryName, models: models as IModel[] }; // Assert that models is of type IModel[]
    }
  );

  // Sort the categories so that 'Other' is last
  return categories.sort((a, b) => {
    if (a.name === 'Other') return 1;
    if (b.name === 'Other') return -1;
    return a.name.localeCompare(b.name); // Optional: sort other categories alphabetically
  });
};
