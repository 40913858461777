import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/models';

export const getDealerPanelModelQuestions = async (
  modelId: number
): Promise<any> => {
  const { data } = await apiClient.get<any>(
    `${BASE_URL}/${modelId}/dealer-panel`
  );
  return data.data;
};
