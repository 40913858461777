import { useNavigate, useLocation, NavigateOptions } from 'react-router-dom';

const useNavigateWithQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithQuery = (to: string, options?: NavigateOptions) => {
    const currentSearchParams = new URLSearchParams(location.search);
    const nextLocation = `${to}${
      currentSearchParams.toString() ? '?' + currentSearchParams.toString() : ''
    }`;
    navigate(nextLocation, options);
  };

  return navigateWithQuery;
};

export default useNavigateWithQuery;
