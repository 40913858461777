import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CircleOuter = styled.div`
  position: fixed;
  z-index: 10;
  inset: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 60rem;
  background-color: ${COLORS.GREEN};
  border-radius: 50%;
`;

export const CircleWhite = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 50rem;
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
`;

export const CircleInner = styled.div`
  width: 40rem;
  height: 40rem;
  background-color: ${COLORS.GREEN};
  border-radius: 50%;
`;

export const HalfCircle = styled.div`
  position: absolute;
  top: 0rem;
  width: 59rem;
  height: 26rem;
  background-color: ${COLORS.GREEN};
  border-radius: 40rem 40rem 0 0;
  animation: ${rotate} 2s linear infinite;
  transform-origin: bottom; // rotate around the bottom center
  z-index: 3;
`;
