import { components } from 'react-select';
import { MultiValueRemoveWrapper } from './CustomMultiValueRemove.styled';

// For use in e2e testing
const CustomMultiValueRemove = (props: any) => {
  return (
    <MultiValueRemoveWrapper data-testid={`${props.pwId}-${props.data.label}`}>
      <components.MultiValueRemove {...props} />
    </MultiValueRemoveWrapper>
  );
};

export default CustomMultiValueRemove;
