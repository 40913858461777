import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/suppliers';

export const getSuppliers = async (
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
