import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import IconButton from '@mui/material/IconButton';

interface StyledProps {
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingTop?: string;
  pointer?: boolean;
  wrapperStyle?: CSSProperties;
  hasRipple?: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  ${(props: StyledProps) =>
    props.hasRipple &&
    css`
      position: relative;
    `}
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  cursor: ${(props: StyledProps) => (props.pointer ? 'pointer' : 'default')};

  ${(props: StyledProps) =>
    props.wrapperStyle &&
    css`
      ${props.wrapperStyle}
    `}

  ${(props: StyledProps) =>
    props.marginLeft &&
    css`
      margin-left: ${(props: StyledProps) => props.marginLeft};
    `}
  ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${(props: StyledProps) => props.marginRight};
    `}
    ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props: StyledProps) => props.marginBottom};
    `}
    ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
    ${(props: StyledProps) =>
    props.paddingLeft &&
    css`
      padding-left: ${(props: StyledProps) => props.paddingLeft};
    `}
    ${(props: StyledProps) =>
    props.paddingRight &&
    css`
      padding-right: ${(props: StyledProps) => props.paddingRight};
    `}
    ${(props: StyledProps) =>
    props.paddingBottom &&
    css`
      padding-bottom: ${(props: StyledProps) => props.paddingBottom};
    `}
    ${(props: StyledProps) =>
    props.paddingTop &&
    css`
      padding-top: ${(props: StyledProps) => props.paddingTop};
    `}
    ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.5;
    `}
`;

export const RippleEffectIconButton = styled(IconButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
    `}
`;
