import { Modal } from 'components/Modals/Modal/Modal';
import { Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IVisibilityConfigurationDTO,
  useGetDealerPanelModelQuestions,
  useGetDocumentVisibilityOptions,
  useGetModelDocumentVisibility,
  useSubmitVisibilityConfiguration,
} from './hooks';
import Button from 'components/Button/Button';
import {
  ButtonsWrapper,
  ErrorOcurredLabel,
  FormContainer,
  FormTitle,
  FormTitleWrapper,
  NoContentLabel,
  QuestionWrapper,
  Scrollable,
} from './ManageModelQuestionDocumentVisibilityModal.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { IQuestion } from 'types/Question.types';
import Loader from 'components/Loader/Loader';
import {
  getInitialValuesFromQuestions,
  parseQuestionsObjectResponseIntoArray,
} from './helpers';

interface IManageModelQuestionDocumentVisibilityModalProps {
  manageVisibilityModelId: number | null;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
}

const ManageModelQuestionDocumentVisibilityModal = ({
  manageVisibilityModelId,
  setManageVisibilityModelId,
}: IManageModelQuestionDocumentVisibilityModalProps) => {
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const formikRef = useRef<any>(null);

  const {
    data: documentVisibilityData,
    mutate: getModelDocumentVisibility,
    isLoading: isLoadingGetModelDocumentVisibility,
    reset: resetGetModelDocumentVisibility,
    isSuccess: isSuccessGetModelDocumentVisibility,
  } = useGetModelDocumentVisibility();

  const {
    data: questionsData,
    mutate: getDealerPanelModelQuestions,
    isLoading: isLoadingGetDealerPanelModelQuestions,
    reset: resetGetDealerPanelModelQuestions,
    isSuccess: isSuccessGetDealerPanelModelQuestions,
  } = useGetDealerPanelModelQuestions();

  const questions: IQuestion[] = questionsData?.questions
    ? parseQuestionsObjectResponseIntoArray(questionsData.questions)
    : [];

  useEffect(() => {
    if (manageVisibilityModelId) {
      getDealerPanelModelQuestions(manageVisibilityModelId);
      getModelDocumentVisibility(manageVisibilityModelId);
    } else {
      resetGetDealerPanelModelQuestions();
      resetGetModelDocumentVisibility();
    }
  }, [manageVisibilityModelId]);

  const {
    mutate: submitVisibilityConfiguration,
    isLoading: isLoadingSubmitVisibilityConfiguration,
  } = useSubmitVisibilityConfiguration(() => setManageVisibilityModelId(null));

  const handleSubmitVisibilityConfiguration = () => {
    const values = formikRef.current.values;
    const payload: IVisibilityConfigurationDTO = {
      values,
      modelId: manageVisibilityModelId!,
    };
    submitVisibilityConfiguration(payload);
  };

  const documentVisibilityOptions = useGetDocumentVisibilityOptions();

  const modalHeight = questions.length * 50 + (questions.length - 1) * 30 + 200;

  return (
    <Modal
      isOpen={!!manageVisibilityModelId}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setManageVisibilityModelId(null);
        }
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        width: '750rem',
        height: `${modalHeight}rem`,
        minHeight: '200rem',
      }}
      onBackDropClick={() => setManageVisibilityModelId(null)}
    >
      {isLoadingGetDealerPanelModelQuestions ? (
        <Loader />
      ) : !questions.length ? (
        <NoContentLabel>
          {t('No questions found for this model')}
        </NoContentLabel>
      ) : isSuccessGetModelDocumentVisibility &&
        isSuccessGetDealerPanelModelQuestions ? (
        <>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            onSubmit={() => {
              //
            }}
            initialValues={getInitialValuesFromQuestions(
              questions,
              documentVisibilityData,
              documentVisibilityOptions
            )}
          >
            {(formikProps: any) => {
              const { values, errors, setFieldValue } = formikProps;

              return (
                <>
                  <FormTitleWrapper>
                    <FormTitle>
                      {t(
                        'Manage document visibility options for model questions'
                      )}
                    </FormTitle>
                  </FormTitleWrapper>

                  <Scrollable overflowVisible={questions.length < 4}>
                    <FormContainer
                      height={`${
                        questions.length * 50 + (questions.length - 1) * 30
                      }rem`}
                    >
                      {questions.map((question: IQuestion, index: number) => (
                        <QuestionWrapper key={index}>
                          <Field
                            isMulti
                            height={'41rem'}
                            width="203rem"
                            name={question.initialKey}
                            options={documentVisibilityOptions}
                            component={CustomSelect}
                            placeholder={question.name}
                            onSelect={(value: string) =>
                              setFieldValue(question.initialKey, value)
                            }
                            errorMessage={
                              areAllFieldsTouched && errors[question.name]
                            }
                            menuPlacement={index < 2 ? 'bottom' : 'top'}
                          />
                        </QuestionWrapper>
                      ))}
                    </FormContainer>
                  </Scrollable>
                </>
              );
            }}
          </Formik>
          <ButtonsWrapper>
            <Button
              disabled={isLoadingSubmitVisibilityConfiguration}
              label={t('Cancel')}
              secondary
              width="200rem"
              onClick={() => setManageVisibilityModelId(null)}
            />
            <Button
              disabled={isLoadingSubmitVisibilityConfiguration}
              label={t('Save')}
              primary
              width="200rem"
              onClick={() => {
                if (formikRef.current?.isValid) {
                  handleSubmitVisibilityConfiguration();
                } else {
                  if (!areAllFieldsTouched) {
                    setAreAllFieldsTouched(true);
                  }
                }
              }}
            />
          </ButtonsWrapper>
        </>
      ) : (
        <ErrorOcurredLabel>{t('An error occurred')}</ErrorOcurredLabel>
      )}
    </Modal>
  );
};

export default ManageModelQuestionDocumentVisibilityModal;
