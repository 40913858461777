import { ThemeProvider } from '@mui/material';
import { useAppTheme } from './hooks';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/fr';
import 'moment/locale/sr';

export const AppThemeProvider = ({ children }: any) => {
  const theme = useAppTheme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
