import { useQuery } from 'react-query';
import { getSuppliers } from 'services/SupplierService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSuppliers = (
  page: number,
  perPage: number,
  searchBy: string
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SUPPLIERS, page, perPage, searchBy],
    queryFn: () => {
      return getSuppliers(page, perPage, searchBy);
    },
  });
