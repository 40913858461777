import { ReactNode, useRef } from 'react';
import {
  Container,
  ChildWrapper,
  TextWrapper,
  Text,
  Tip,
  Scrollable,
} from './Tooltip.styled';
import { useTooltipPosition } from './useTooltipPosition';

interface ITooltip {
  children: ReactNode;
  text: string;
  width?: string;
  withoutWordBreak?: boolean;
  isBelow?: boolean;
  isHuge?: boolean;
}

export const Tooltip = ({
  children,
  text,
  width,
  withoutWordBreak = false,
  isBelow = false,
  isHuge = false,
}: ITooltip) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tipRef = useRef<HTMLDivElement | null>(null);

  const positionTooltip = useTooltipPosition();

  const handleMouseOver = () => {
    const container = containerRef.current;
    const tip = tipRef.current;
    if (!container || !tip) return;

    positionTooltip(container.children[0] as HTMLDivElement, container, tip);
  };

  return (
    <Container onMouseOver={handleMouseOver} ref={containerRef}>
      <TextWrapper isBelow={isBelow} width={width}>
        <Scrollable isHuge={isHuge} width={width}>
          <Text withoutWordBreak={withoutWordBreak}>{text}</Text>
          <Tip isBelow={isBelow} ref={tipRef} />
        </Scrollable>
      </TextWrapper>

      <ChildWrapper>{children}</ChildWrapper>
    </Container>
  );
};

export default Tooltip;
