import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';
import { store } from 'store/store';
import { Base64 } from 'js-base64';
import { IVisibilityConfigurationDTO } from 'pages/ModelsPage/ManageModelQuestionDocumentVisibilityModal/hooks';

export const submitVisibilityConfiguration = async (
  visiblityConfigurationDTO: IVisibilityConfigurationDTO
): Promise<any> => {
  const webhookUrl = store.getState().commonInfo.webhookURL;
  const metadata = store.getState().commonInfo.metadata;

  const queryParamsObj = {
    metadata: Base64.encode(JSON.stringify(metadata)),
    logyx_model_id: visiblityConfigurationDTO.modelId,
  };
  const fullUrl = getUrlWithQueryParams(webhookUrl, queryParamsObj);

  const { data } = await apiClient.post<any>(fullUrl, {
    data: visiblityConfigurationDTO.values,
  });

  return data.data;
};

export const getModelDocumentVisibility = async (
  modelId: number
): Promise<any> => {
  const webhookUrl = store.getState().commonInfo.webhookURL;
  const metadata = store.getState().commonInfo.metadata;

  const queryParamsObj = {
    metadata: Base64.encode(JSON.stringify(metadata)),
    logyx_model_id: modelId,
  };
  const fullUrl = getUrlWithQueryParams(webhookUrl, queryParamsObj);

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
