import { components } from 'react-select';
import { LoaderWrapper } from './CustomDropdownIndicator.styled';
import Loader from 'components/Loader/Loader';
import Icon from 'components/Icon/Icon';
import { CaretDown } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const CustomDropdownIndicator = ({ isLoading, ...rest }: any) => {
  return (
    <components.DropdownIndicator {...rest}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Icon svg={CaretDown} color={COLORS.BLACK} />
      )}
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
