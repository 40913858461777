import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import {
  ICreateConfigurationData,
  IUpdateConfigurationData,
} from 'types/Configuration.types';

const BASE_URL = '/v1/configurations';

export const getModelConfigurations = async (
  supplierId: string | null,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    supplier_id: supplierId,
    search: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const createConfiguration = async (
  createConfigurationData: ICreateConfigurationData
): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, createConfigurationData);
  return data.data;
};

export const updateConfiguration = async (
  configurationId: number,
  updateConfigurationData: IUpdateConfigurationData
): Promise<any> => {
  const url = `${BASE_URL}/${configurationId}`;
  const { data } = await apiClient.patch<any>(url, updateConfigurationData);
  return data.data;
};
