import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { decode } from 'js-base64';
import { setWebhookURL } from 'store/Common/actions/common';

interface IWebhookURLQueryParamListenerProps {
  children: React.ReactNode;
}

const WebhookURLQueryParamListener = ({
  children,
}: IWebhookURLQueryParamListenerProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const base64WebhookUrl = queryParams.get('webhookUrl');
  let webhookUrl: any;
  if (base64WebhookUrl) {
    webhookUrl = decode(base64WebhookUrl);
  }

  useEffect(() => {
    if (webhookUrl) {
      dispatch(setWebhookURL(webhookUrl));
    }
  }, [webhookUrl]);

  return <>{children}</>;
};

export default WebhookURLQueryParamListener;
