import { ButtonBase } from '@mui/material';
import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const StyledButtonBase = styled(ButtonBase)`
  &:hover {
    background-color: ${COLORS.RIPPLE_EFFECT_OPACITY};
    border-radius: 50%;
  }

  .MuiTouchRipple-child {
    // Ripple that appears when long pressing on the component
    background-color: ${COLORS.RIPPLE_CHILD_EFFECT_OPACITY};
  }
`;
