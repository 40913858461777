import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm } from 'assets/styled';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLORS.WHITE};
  width: 100%;
  min-width: 1000rem;
  position: fixed;
  z-index: 10;
  border-bottom: 1rem solid ${COLORS.GRAY_100};
`;

export const LeftSide = styled.div``;
export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LogyxLogo = styled.img`
  width: 122rem;
  cursor: pointer;
`;

export const NavigationLabel = styled.label`
  cursor: pointer;
  margin-left: ${marginSm};
  margin-right: ${marginSm};
  font-weight: 400;
  ${(props: StyledProps) =>
    props.isSelected
      ? css`
          color: ${COLORS.GREEN};
          transform: scale(1.1);
          transition: ease 100ms;
          font-weight: 600;
        `
      : css`
          color: ${COLORS.BLACK};
          transition: ease 100ms;
        `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75rem;
  padding: 0rem 50rem;
`;

export const NavigationLabelsWrapper = styled.div`
  width: 250rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SwitchersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50rem;
`;
