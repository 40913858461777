import styled from 'styled-components';

import { COLORS } from './colors';

export const H1 = styled.h1`
  font-size: 46rem;
`;

export const H2 = styled.h2`
  font-size: 30rem;
  line-height: 41rem;
  font-weight: 400;
`;

export const H3 = styled.h3`
  font-size: 24rem;
  line-height: 32rem;
  font-weight: 400;
`;

export const H4 = styled.h4`
  font-size: 18rem;
  line-height: 27rem;
  font-weight: 300;
`;

export const P = styled.p`
  font-size: 16rem;
  font-weight: 400;
  line-height: 21.79rem;
  color: ${COLORS.BLACK};
`;
export const H5 = styled.p`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32.68rem;
  color: ${COLORS.BLACK};
  :hover {
    color: ${COLORS.GREEN};
  }
`;
export const Link = styled.a`
  display: inline-block;
  font-size: 12rem;
  color: ${COLORS.BLACK};
`;

export const Label = styled.label`
  color: ${COLORS.BLACK};
`;
