import Button from 'components/Button/Button';
import { Container, PageNotFoundLabel } from './PageNotFound.styled';
import useNavigateWithQuery from 'navigation/useNavigateWithQuery';

const PageNotFound = () => {
  const navigate = useNavigateWithQuery();
  return (
    <Container>
      <PageNotFoundLabel>Page not found</PageNotFoundLabel>
      <Button
        primary
        label={'Return'}
        width={'150rem'}
        onClick={() => navigate('/')}
      />
    </Container>
  );
};

export default PageNotFound;
