import { COLORS, borderRadiusSm, marginMd, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300rem;
  margin: ${marginMd};
`;

export const Image = styled.img`
  object-fit: contain;
  border-radius: ${borderRadiusSm};
  cursor: pointer;
  border: 1px solid ${COLORS.GRAY_100};
`;

export const NameLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  cursor: pointer;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10rem;
  margin-top: ${marginSm};
`;

export const AbsoluteCircleWrapper = styled.label`
  position: absolute;
  display: flex;
  right: 0rem;
  top: 0rem;
  background-color: ${COLORS.GREEN_DISABLED};
  border-bottom-left-radius: ${borderRadiusSm};
  border-top-right-radius: ${borderRadiusSm};
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const SupplierIdLabel = styled.label`
  margin-left: 3rem;
  margin-right: 3rem;
`;
