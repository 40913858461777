import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const useHtmlTheme = () => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'dark' : 'light'
    );
  }, [darkMode]);
};
