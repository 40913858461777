import { useState, useEffect, useMemo } from 'react';

function getSnapshot() {
  return { width: window.innerWidth, height: window.innerHeight };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getSnapshot());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getSnapshot());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const memoizedDimensions = useMemo(
    () => windowDimensions,
    [windowDimensions]
  );

  return memoizedDimensions;
}
