import { StyledButtonBase } from './StyledButtonBase.styled';

interface IRippleEffectProps {
  children: React.ReactNode;
  size?: number;
  onClick?: () => void;
}

const RippleEffect = ({ children, size = 40, onClick }: IRippleEffectProps) => {
  const remSizeString = Math.max(40, size) + 'rem';
  return (
    <StyledButtonBase
      centerRipple
      sx={{ width: remSizeString, height: remSizeString, padding: '0rem' }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </StyledButtonBase>
  );
};

export default RippleEffect;
